.sf__customer__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  // padding: 1rem 0;
  // height: 100vh;
  // background: white;
  overflow-x: hidden;
}

.sf__avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.sf__head__con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sf__share {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.sf__share__options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  span {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
  }

  img {
    width: 20px;
  }
}

.sf__share__con {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sf__customer__maintenance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;

  img {
    width: 70%;
  }
  @media (max-width: 660px) {
    img {
      width: 90%;
    }
  }
}

.sf__customer__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: center;
  width: 90%;
  height: 100vh;
  gap: 0rem;

  padding: 1rem 0;
}

.sf__customer__top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.sf__customer__logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.sf__customer__cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;

  @media (max-width: 660px) {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: flex-start;
    // flex-direction: column;
    // gap: 0.8rem;
  }
}

.editsf__submit {
  width: 30%;

  @media (max-width: 660px) {
    width: 50%;
  }
}

.sf__customer__logo span {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;

  h1 {
    font-size: 60px;
  }
  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
}

.sf__customer__head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sf__customer__head h3 {
  margin: 0;
  padding: 0;
  font-weight: 800;
}

.sf__customer__head p {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.sf__customer__socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  font-size: 25px;
}

.sf__customer__buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
  gap: 0.5rem;
}

.sf__customer__buttons button {
  width: 60%;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  color: white;
  font-weight: 600;

  @media (max-width: 660px) {
    width: 80%;
  }
}

// .sf__customer__buttons a {
//   width: 60%;

//   button {
//     padding: 0.5rem 1rem;
//     border-radius: 0.5rem;
//     outline: none;
//     border: none;
//     color: white;
//     font-weight: 600;
//     width: 100%;
//   }

//   @media (max-width: 660px) {
//     width: 80%;
//   }
// }

.sf__customer__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.3rem;
  margin-top: 1rem;

  img {
    width: 30px;
    height: 30px;
  }
}

.avatar__modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 1rem; */
}

.avatar__modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.avatar__modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

// .avatar__modal-content button {
//   display: flex;
//   width: 60%;
//   border: none;
//   outline: none;
//   justify-content: center;
//   align-items: center;
//   padding: 0.5rem;
//   border-radius: 0.3rem;
//   background-color: #3a417c;
//   color: white;
//   cursor: pointer;
// }

// .avatar__modal-content button:hover {
//   opacity: 0.7;
// }

.avatar__close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: gray;
}
@media screen and (max-width: 400px) {
  .avatar__modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    display: flex;
    width: 320px;
  }
}

@media screen and (max-width: 360px) {
  .avatar__modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    display: flex;
    width: 310px;
  }
}
