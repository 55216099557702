.fund__wallet__btn {
  font-size: 13px;
  border: none;
  margin-top: 0.5rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  background-color: #007bff;
  color: #ffffff;
}

.fund__div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
